import { Link } from "gatsby"
import React, { Component } from "react"

import Logo from "../../svg/concord-logo.svg"
import styles from "./footer.module.scss"

import SocialFacebook from "../../svg/social-facebook.svg"
import SocialInstagram from "../../svg/social-instagram.svg"
import SocialLinkedIn from "../../svg/social-linkedin.svg"
import SocialTwitter from "../../svg/social-twitter.svg"

class Footer extends Component {
  constructor (props) {
    super(props)

    this.footerRef = React.createRef()

    this.state = {
      footerHeight: 0
    }
  }

  componentDidMount () {
    this.boundResizeFunction = this.resizeFunction.bind(this)

    window.addEventListener('resize', this.boundResizeFunction)

    this.setFooterHeight()
  }

  setFooterHeight () {
    if (this.footerRef.current) {
      this.setState({ footerHeight: this.footerRef.current.clientHeight })
    }
  }

  resizeFunction () {
    this.setFooterHeight()
  }

  render () { 
    return (
      <React.Fragment>
        <div
          className={styles.footerPlaceholder}
          style={{
            height: `${this.state.footerHeight}px`
          }}
        />
        <footer
          className={`${styles.footer} t-small`}
          ref={this.footerRef}
        >
          <div className={`${styles.wrapper} limit-width`}>
            <div className={styles.footerSide}>
              <div className={`${styles.col} ${styles.title}`}>
                <Link to="/" className={styles.logo}>
                  <Logo />
                </Link>
              </div>
              <div className={styles.col}>
                <p className="t-strong">
                  Sitemap
                </p>

                <div className={styles.linkGroups}>
                  <ul className={styles.linkGroup}>
                    <li>
                      <Link to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/commodities">
                        Commodities
                      </Link>
                    </li>
                    <li>
                      <Link to="/sustainability">
                        Sustainability
                      </Link>
                    </li>
                  </ul>

                  <ul className={styles.linkGroup}>
                    <li>
                      <Link to="/partners">
                        Assets
                      </Link>
                    </li>
                    <li>
                      <Link to="/news-media">
                        News & Media
                      </Link>
                    </li>
                    <li>
                      <Link to="/careers">
                        Careers
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>

                  <ul className={styles.linkGroup}>
                    <li>
                      <Link to="/investor-relations">
                        Client Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions">
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`${styles.footerSide} ${styles.copyright}`}>
              <div className={styles.col}>
                <p className="t-strong">
                  Social
                </p>

                <ul className={styles.socialLinks}>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/concord-resources-limited/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialLinkedIn />
                    </a>
                  </li>
                </ul>
              </div>
              <div className={styles.col}>
                <p>&copy; {new Date().getFullYear()}</p>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    )
  }
}

export default Footer

// In src/prismic-configuration.js
export const linkResolver = (doc) => {
  if (doc.type === 'services') {
    return `/services/${doc.uid}`
  }

  if (doc.type === 'case_studies') {
    return `/case-studies/${doc.uid}`
  }

  if (doc.type === 'news_articles') {
    return `/news-media/${doc.uid}`
  }

  if (doc.type === 'policies') {
    return `/policies/${doc.uid}`
  }

  if (doc.type === 'partners') {
    return `/partners`
  }

  if (doc.type === 'about') {
    return `/about`
  }

  if (doc.type === 'contact') {
    return `/contact`
  }

  if (doc.type === 'text_page') {
    return `/${doc.uid}`
  }

  if (doc.type === 'sustainability') {
    return `/sustainability`
  }

  if (doc.type === 'team') {
    return `/team`
  }

  if (doc.type === 'commodities') {
    return `/commodities`
  }

  if (doc.type === 'previous_resolutions') {
    return `/past-resolutions`
  }

  if (doc.type === 'investor_relations') {
    return `/investor-relations`
  }

  // Backup for all other types
  return '/'
}
import { Link } from "gatsby"
import Cookie from "js-cookie"
import React, { useState } from "react"

import styles from "./cookieBar.module.scss"

function CookieBar () {
  const [consentGiven, setConsentGiven] = useState(false)

  if (process.browser) {
    window.dataLayer = window.dataLayer || [];
  }

  function acceptCookies () {
    Cookie.set('consent_given', 'true')
    window.dataLayer.push({'event': 'consent_given'})
    setConsentGiven(true)
  }

  function denyCookies () {
    Cookie.set('consent_given', 'false')
    setConsentGiven(true)
  }

  if (Cookie.get('consent_given') || consentGiven === true) {
    return null
  }

  return (
    <div className={styles.bar}>
      <div className={`${styles.wrapper} limit-width`}>
        <div className={styles.text}>
          This website uses cookies to ensure you get the best experience on our website. <Link to="/privacy-policy">Read Privacy Policy</Link>
        </div>
        <div className={styles.buttons}>
          <button
            className={`${styles.button} button`}
            onClick={() => acceptCookies()}
          >
            Accept
          </button>

          <button 
            className={`${styles.button} button`}
            onClick={() => denyCookies()}
          >
            Deny
          </button>
        </div>
      </div>
    </div>
  )
}

export default CookieBar

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import CookieBar from "../components/cookieBar/cookieBar"

import vhCheck from 'vh-check'

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

const Layout = ({ children }) => {
  if (typeof document !== 'undefined') {
    const test = vhCheck()
  }

  return (
    <React.Fragment>
      <Header />
      <main className="main">{children}</main>
      <Footer />
      <CookieBar />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  if (action.type === `TOGGLE_NAVOPEN`) {
    return Object.assign({}, state, {
      isNavOpen: !state.isNavOpen
    })
  } else if (action.type === `CLOSE_NAV`) {
    return Object.assign({}, state, {
      isNavOpen: false
    })
  } else if (action.type === `UPDATE_HEADERHEIGHT`) {
    return Object.assign({}, state, {
      headerHeight: action.value
    })
  } else if (action.type === `UPDATE_WINDOWHEIGHT`) {
    return Object.assign({}, state, {
      windowHeight: action.value
    })
  } else if (action.type === `SET_ACTIVENAVSECTION`) {
    return Object.assign({}, state, {
      activeNavSection: action.value
    })
  } else if (action.type === `SET_BACKGROUNDCOLOR`) {
    return Object.assign({}, state, {
      backgroundColor: action.value
    })
  } else if (action.type === `SET_HEADERTRANSPARENT`) {
    return Object.assign({}, state, {
      headerTransparent: action.value
    })
  } else if (action.type === `TOGGLE_GRID`) {
    return Object.assign({}, state, {
      gridVisible: !state.gridVisible
    })
  }
  return state
}

const initialState = {
  isNavOpen: false,
  headerHeight: 0,
  windowHeight: 0,
  backgroundColor: 'dark',
  activeNavSection: null,
  headerTransparent: false,
  gridVisible: false
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore
import { Link } from "gatsby"
import { connect } from 'react-redux'
import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"

import { linkResolver } from '../../utils/linkResolver'

import Nav from "./nav"
import Logo from "../../svg/concord-logo.svg"
import Icon from "../../svg/concord-icon.svg"
import styles from "./header.module.scss"

class Header extends Component {
  constructor (props) {
    super(props)

    this.headerRef = React.createRef()

    this.boundResizeFunction = null

    this.state = {
      isNavOpen: false
    }
  }

  handleNavOpen = event => {
    // console.log('handleModalOpen: ', event);
    this.setState({ isNavOpen: true })
  }

  handleNavClose = event => {
    // console.log('handleModalOpen: ', event);
    this.setState({ isNavOpen: false })
  }

  handleNavToggle = event => {
    // console.log('handleModalOpen: ', event);
    this.setState({ isNavOpen: !this.state.isNavOpen })
  }

  componentDidMount () {
    this.boundResizeFunction = this.resizeFunction.bind(this)

    window.addEventListener('resize', this.boundResizeFunction)

    this.setHeaderHeight()
  }

  setHeaderHeight () {
    if (this.headerRef.current) {
      this.props.updateHeaderHeight(this.headerRef.current.clientHeight)
    }

    this.props.updateWindowHeight(window.innerHeight)
  }

  resizeFunction () {
    this.setHeaderHeight()
  }

  render () { 
    const pageQuery = graphql`
    {
      prismic {
        allHomepages {
          edges {
            node {
              services {
                service {
                  ... on PRISMIC_Services {
                    title
                    introduction_text
                    _meta {
                      uid
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`

    return (
      <StaticQuery
        query={`${pageQuery}`}
        render={data => {
          const doc = data.prismic.allHomepages.edges.slice(0,1).pop()

          return (
            <React.Fragment>
              <header
                className={`${styles.header} ${this.props.headerTransparent ? styles.headerTransparent : ''} ${this.state.isNavOpen ? styles.isNavOpen : ''}`}
                ref={this.headerRef}
              >
                <div className={styles.wrapper}>
                  <div className={styles.icon} />
                  <Link to="/" className={styles.logo}>
                    <Logo />
                  </Link>
                  <button
                    className={`${styles.button}`}
                    onClick={this.handleNavToggle}
                  >
                    <span />
                    <span />
                    <span />
                  </button>
                </div>
              </header>
              <Nav isNavOpen={this.state.isNavOpen} close={this.handleNavClose} services={doc.node.services} />
            </React.Fragment>
          )
        }}
      />
    )
  }
}

Header.propTypes = {
  headerTransparent: PropTypes.bool
}

const mapDispatchToProps = dispatch => {
  return {
    updateHeaderHeight: (val) => dispatch({ type: `UPDATE_HEADERHEIGHT`, value: val }),
    updateWindowHeight: (val) => dispatch({ type: `UPDATE_WINDOWHEIGHT`, value: val })
  }
}

const mapStateToProps = ({ headerTransparent }) => {
  return { headerTransparent }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
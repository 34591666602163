import { Link } from "gatsby"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { RichText } from 'prismic-reactjs'
import { CSSTransition } from 'react-transition-group'

import { linkResolver } from '../../utils/linkResolver'

import Icon from "../../svg/concord-icon.svg"
import styles from "./header.module.scss"

const Nav = ( props ) => {
  return (
    <React.Fragment>
      <CSSTransition
        in={props.isNavOpen}
        timeout={650}
        unmountOnExit
        classNames={{
          enter: styles.navAnimateEnter,
          enterActive: styles.navAnimateEnterActive,
          exit: styles.navAnimateExit,
          exitActive: styles.navAnimateExitActive
        }}
      >
        <div className={styles.navOverlay}>
          <nav className={styles.nav}>
            <div className={styles.navWrapper}>
              <div className={styles.navHeader} />
              <div className={`${styles.navList} ${styles.navListDesktop}`}>
                <div className="cols-wrapper">
                  <div className="cols cols-center">
                    <ul className="col-3-md col-3-lg">
                      <li>
                        <Link
                          to="/about"
                          activeClassName={styles.isActive}
                        >
                          About
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/commodities"
                          activeClassName={styles.isActive}
                        >
                          Commodities
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/sustainability"
                          activeClassName={styles.isActive}
                        >
                          Sustainability
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/partners"
                          activeClassName={styles.isActive}
                        >
                          Assets
                        </Link>
                      </li>
                    </ul>
                    <ul className="col-3-md col-3-lg">
                      {props.services &&
                        <li>
                          <p>
                            Services
                          </p>
                          <ul>
                            {props.services.map((item, index) => (
                              <li key={index}>
                                <Link
                                  to={linkResolver(item.service._meta)}
                                  activeClassName={styles.isActive}
                                >
                                  {RichText.asText(item.service.title)}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      }
                    </ul>
                    <ul className="col-3-md col-3-lg">
                      <li>
                        <Link
                          to="/news-media"
                          activeClassName={styles.isActive}
                        >
                          News & Media
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/careers"
                          activeClassName={styles.isActive}
                        >
                          Careers
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/investor-relations"
                          activeClassName={styles.isActive}
                        >
                          Client login
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact"
                          activeClassName={styles.isActive}
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={`${styles.navList} ${styles.navListMobile}`}>
                <ul>
                  <li>
                    <Link to="/about">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/commodities">
                      Commodities
                    </Link>
                  </li>
                  {props.services &&
                    <li>
                      <p>
                        Services
                      </p>
                      <ul>
                        {props.services.map((item, index) => (
                          <li key={index}>
                            <Link to={linkResolver(item.service._meta)}>
                              {RichText.asText(item.service.title)}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  }
                  <li>
                    <Link to="/sustainability">
                      Sustainability
                    </Link>
                  </li>
                  <li>
                    <Link to="/partners">
                      Assets
                    </Link>
                  </li>
                  <li>
                    <Link to="/news-media">
                      News & Media
                    </Link>
                  </li>
                  <li>
                    <Link to="/careers">
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link to="/investor-relations">
                      Client Login
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <button
            className={styles.shroud}
            onClick={props.close}
          />
        </div>
      </CSSTransition>
    </React.Fragment>
  )
}

Nav.propTypes = {
  isNavOpen: PropTypes.bool,
  close: PropTypes.func
}

Nav.defaultProps = {
  isNavOpen: false
}

export default Nav